<template>
  <Page class="page-purchases page-show-purchase">
    <template #title>Dettaglio acquisto</template>

    <template #default>
      <div class="table-responsive">
        <table class="table table-condensed">
          <tbody>
          <tr v-if="isAdmin">
            <th>Negozio</th>
            <td>{{ (purchase.shop || {}).name || purchase.shopName }}</td>
          </tr>
          <tr>
            <th>Offerta</th>
            <td v-if="purchase.is_item_purchase">{{ purchase.itemName }}</td>
            <td v-else>Pagamento diretto</td>
          </tr>
          <tr>
            <th>Pagamento</th>
            <td>
              <template v-if="purchase.payment_status === 'pending'">
                In attesa
                <template v-if="isMerchant && purchase.waitingForMerchant">
                  <br>
                  <button type="button" class="btn btn-outline-success mr-3" @click.prevent="acceptPurchase">Accetta</button>
                  <button type="button" class="btn btn-outline-danger" @click.prevent="rejectPurchase">Rifiuta</button>
                </template>
              </template>
              <template v-else-if="purchase.payment_status === 'rejected'">Rifiutato</template>
              <template v-else-if="purchase.payment_status === 'completed'">Completato</template>
              <template v-else>-</template>
            </td>
          </tr>
          <tr>
            <th>Data di acquisto</th>
            <td>{{ purchase.created_at | date }}</td>
          </tr>
          <tr>
            <th>Importo</th>
            <td>{{ purchase.amount_paid | currency }}</td>
          </tr>
          <tr>
            <th>Quota merchant</th>
            <td>{{ purchase.merchant_amount | currency }}</td>
          </tr>
          <tr>
            <th>Quota eziCommerce</th>
            <td>{{ purchase.total_fee | currency }}</td>
          </tr>
          <tr>
            <th>Acquirente</th>
            <td>{{ purchase.customerEmail }}</td>
          </tr>
          <tr>
            <th>Richiesta spedizione</th>
            <td v-if="!purchase.withShipping">No</td>
            <td v-else>
              {{ purchase.shippingData.name }}<br>
              {{ purchase.shippingData.address }}<br>
              {{ purchase.shippingData.zipCode }} - {{ purchase.shippingData.town }} ({{ purchase.shippingData.province }})
            </td>
          </tr>
          <tr>
            <th>Richiesta fattura/ricevuta</th>
            <td v-if="!purchase.withInvoice">No</td>
            <td v-else>
              {{ purchase.invoiceData.name }}<br>
              {{ purchase.invoiceData.address }}<br>
              {{ purchase.invoiceData.zipCode }} - {{ purchase.invoiceData.town }} ({{ purchase.invoiceData.province }})<br>
              <template v-if="purchase.invoiceData.vat">
                P.Iva: {{ purchase.invoiceData.vat }}<br>
              </template>
              <template v-if="purchase.invoiceData.fiscalCode">
                Cod. Fiscale: {{ purchase.invoiceData.fiscalCode }}<br>
              </template>
              <template v-if="purchase.invoiceData.pec">
                Pec: {{ purchase.invoiceData.pec }}
              </template>
              <template v-else-if="purchase.invoiceData.sdi">
                SDI: {{ purchase.invoiceData.sdi }}
              </template>
            </td>
          </tr>
          <tr v-if="purchase.redeemRequired && purchase.payment_status === 'completed'">
            <th>Riscattato</th>
            <td v-if="!purchase.redeemed">
              No
              <template v-if="isMerchant">
                <br>
                <button type="button" class="btn btn-outline-primary" @click.prevent="redeemPurchase">Riscatta manualmente</button>
              </template>
            </td>
            <td v-else>{{ purchase.redeemed_at | date }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
  </Page>
</template>

<script>

import rolesMixin from '@/mixins/roles.js';
import confirmWithModalMixin from '@/libs/Confirm/mixins/confirmWithModal.js';
import formatCurrency from '@/filters/formatCurrency.js';
import formatDate from '@/filters/formatDate.js';
import Page from '@/views/components/Private/Page.vue';

export default {
  mixins: [rolesMixin, confirmWithModalMixin],
  components: {
    Page,
  },
  filters: {
    currency: formatCurrency,
    date: formatDate,
  },
  props: {
    purchase: {
      type: Object,
      required: true,
    },
  },
  methods: {
    acceptPurchase () {
      if (!this.isMerchant || !this.purchase.waitingForMerchant) {
        return;
      }

      this.confirm('Accettare il pagamento? Questa operazione non può essere revocata', 'Ok', 'Annulla')
        .then(confirmed => {
          if (!confirmed) {
            return;
          }

          this.$api
            .acceptPurchase(this.purchase.id)
            .then(res => {
              this.$emit('update:purchase', res.data);
            })
            .catch(this.$log.error)
          ;
        })
      ;
    },
    rejectPurchase () {
      if (!this.isMerchant || !this.purchase.waitingForMerchant) {
        return;
      }

      this.confirm('Rifiutare il pagamento? Questa operazione non può essere revocata', 'Ok', 'Annulla')
        .then(confirmed => {
          if (!confirmed) {
            return;
          }

          this.$api
            .rejectPurchase(this.purchase.id)
            .then(res => {
              this.$emit('update:purchase', res.data);
            })
            .catch(this.$log.error)
          ;
        })
      ;
    },
    redeemPurchase () {
      if (!this.isMerchant || this.purchase.redeemed) {
        return;
      }

      this.confirm('Riscattare il pagamento? Questa operazione non può essere revocata', 'Ok', 'Annulla')
        .then(confirmed => {
          if (!confirmed) {
            return;
          }

          this.$api
            .redeemPurchase(this.purchase.id)
            .then(res => {
              this.$emit('update:purchase', res.data);
            })
            .catch(this.$log.error)
          ;
        })
      ;
    },
  },
};

</script>
